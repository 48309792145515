<script>
    import Layout from "@/router/layouts/template-pemohon";
    import appConfig from "@/app.config";
    import PageHeader from "@/components/page-header";

    /**
     * Dashboard Component
     */
    export default {
        page: {
            title: "Dashboard",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
        },
        data() {
            return {
                title: "Daftar Permohonan",
                items: [
                    {
                        text: "Dashboards",
                        active: true,
                    },
                    
                ],
				// Filter
				start: null,
				end: null,
				status: "",
                filter_status: [
                    {
                        value: "draft",
                        label: "Draft",
                    },
                    {
                        value: "baru",
                        label: "Baru",
                    },
                    {
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
                    {
                        value: "dibatalkan",
                        label: "Dibatalkan",
                    },
                    {
                        value: "proses",
                        label: "Proses",
                    },
                    {
                        value: "selesai",
                        label: "Selesai",
                    },
                ],
                // Table
				loadingTable: false,
				dataTable: [],
				showDataFromTable: 0,
				showDataToTable: 0,
				totalDataTable: 0,
            };
        },
        mounted() {
            setTimeout(() => {
                this.showModal = true;
            }, 1500);
        },
		
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" />
		<div class="row">
            <div class="col-lg-12">
                <div class="card">
					<div class="card-header top-radius-15 bg-success"><h4 class="my-0 text-center text-white"><b>Semua Permohonan</b></h4></div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 my-3">
                                <div class="row">
									<div class="col-md-11">
										<div class="row">
											<div class="col-md">
												<div class="form-group">
													<b for="">Tgl Dari</b>
													<Datepicker v-model="start" :enable-time-picker="false" auto-apply no-today teleport-center  />
												</div>
											</div>
											<div class="col-md">
												<div class="form-group">
													<b for="">Tgl Sampai</b> 
													<Datepicker v-model="end" :enable-time-picker="false" auto-apply no-today teleport-center />
												</div>
											</div>
											
											<div class="col-md-3">
												<div class="form-group">
													<b for="">Status</b>
													<v-select :options="filter_status" label="label" v-model="status"></v-select>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-1">
										<br>
										<div class="btn btn-primary" v-on:click="filterPermohonan"><i class="fa fa-filter"></i> Filter</div>
									</div>
								</div>
                                
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0 table-bordered table-condensed table-hover">
                                        <thead class="bg-dark text-center text-white">
                                            <tr>
                                                <th style="width: 50px;">No</th>
                                                <th>Kode Permohonan</th>
                                                <th>Perizinan</th>
												<th>Permohonan</th>
                                                <th style="width: 175px;">Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="loadingTable">
                                                <td colspan="5"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                            </tr>
                                            <tr v-else>
                                                <td colspan="5"><i class="fas fa-times"></i> Data Tidak Tersedia</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="pt-4">
                                    Show {{ showDataFromTable }}-{{ showDataToTable }} From {{ totalDataTable }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div style="float: right !important;">
                                    <nav aria-label="pagination" class="mt-3">
                                        <ul class="pagination justify-content-start">
                                            <li v-for="(page, key_page) in pagingTable" v-bind:class="{ 'active': page.active }" :key="key_page" class="page-item">
                                                <div v-on:click="toPage(page.url)" class="page-link c-page-link-number"> 
                                                    <div v-html="page.label"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
